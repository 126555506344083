import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { Section } from "../components/Containers"
import { Image, Text } from "../components/Core"

const MeetOurTeam = ({ data, pageContext }) => {
  const { metaTitle, metaDescription, heading } =
    data.allUniquePagesJson.nodes[0]
  const language = pageContext.language
  const team = data.team.nodes[0].meetOurTeam.staffImages

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO title={metaTitle} description={metaDescription} lang={language} />
        <div className="meet-team">
          <Section>
            <div className="meet-team__container">
              <Text as="h1" className="has-text-centered" text={heading} />
              <div className="meet-team__staff-list">
                {team.map(({ staffMember }, i) => (
                  <div key={i} className="meet-team__card">
                    <Image publicId={staffMember.imageId} />
                    <div>
                      <h2>{staffMember.staffName}</h2>
                      <h3>{staffMember.staffTitle}</h3>
                      <p>{staffMember.bio}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const meetTeamPageQuery = graphql`
  query MyQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        metaDescription
        metaTitle
      }
    }
    team: allUniquePagesJson(filter: { template: { eq: "about-us" } }) {
      nodes {
        meetOurTeam {
          staffImages {
            staffMember {
              bio
              imageId
              staffName
              staffTitle
            }
          }
        }
      }
    }
  }
`

export default MeetOurTeam
